<template>
  <div class="home">
      <!-- 选择年级版本上下册 -->
      <el-container>
  <el-header>
    <div>
      <div style="margin: 16px;margin-left: 0;">
        <el-breadcrumb separator="|">  
        <el-breadcrumb-item :to="{ path: '/' }">我的任务</el-breadcrumb-item>
        <el-breadcrumb-item >工作台</el-breadcrumb-item>
       
  
 
</el-breadcrumb>
      </div>
      
<div style="margin: 16px;margin-left: 100px;">
  <el-row>
    <el-col :span="8">
<el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item>一年级</el-breadcrumb-item>
  <el-breadcrumb-item>语文</el-breadcrumb-item>
  <el-breadcrumb-item>人教版</el-breadcrumb-item>
  <el-breadcrumb-item>上册</el-breadcrumb-item>
  <el-breadcrumb-item>教辅</el-breadcrumb-item>
</el-breadcrumb>
    </el-col>
    <el-col :span="12">
      <div @click="tabClick">
      <el-radio v-model="isTable" label="b"  
     style="z-index: 10;">整张表格</el-radio>
     <el-radio v-model="isTable" label="a"
     style="z-index: 10;">拆分表格</el-radio>
        <el-select style="z-index: 10;"
        v-model="type" placeholder="选择题目类型" >
              <el-option v-for="item in options" :key="item" :value="item" clearable>
              </el-option>
            </el-select>
</div>
    </el-col>
  </el-row>
    
</div>
</div>

<div style="position: absolute;top: 30px;right:40px">
  <el-image
              style="width: 60px; height: 80px"
              :src="task.image || 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'"
            >
            </el-image>
</div>
      <!-- <el-form :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-select
          v-model="gradeValue"
          placeholder="选择年级"
        >
          <el-option
            v-for="item in gradeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="subjectValue"
          placeholder="选择科目"
        >
          <el-option
            v-for="item in subjectOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="editionName"
          placeholder="版本和上下册"
        >
          <el-option
            v-for="item,index in editionOption"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择教辅"> -->
        <!-- <el-select
          v-model="editionName"
          @change="editionChange"
          placeholder="版本和上下册"
        >
          <el-option
            v-for="item,index in editionOption"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select> 
      </el-form-item>
    </el-form>-->
  </el-header>
    <el-container>
      <el-aside :width="asideWidth">
        <!-- 侧边栏 -->

        <el-card class="box-card">
  <div slot="header" class="clearfix">
    <span @click="isEditChange">{{ task.title }}
      <span v-show="!isEdit" ><i class="el-icon-arrow-right"></i></span>
     <span v-show="isEdit"><i class="el-icon-arrow-left"></i></span></span>
    <el-button style="float: right; padding: 3px 0" type="text"
    v-show="isEdit"
    @click="appendMain">添加1级目录</el-button>
  </div>
  <el-tree ref="tree" :data="data" node-key="id" show-checkbox default-expand-all
        @check="treeCheck" check-strictly
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span v-if="!data.editLabel && data.level==5">{{ data.name }}</span>
              <span v-if="!data.editLabel && data.level==6" style="color:#409eff">{{ data.name }}</span>
              <span v-if="!data.editLabel && data.level==7" style="color:#E6A23C">{{ data.name }}</span>
              <span v-if="!data.editLabel && data.level==8" style="color:#f56c6c">{{ data.name }}</span>
          <input
            type="text"
            v-model="data.name"
            v-if="data.editLabel"
            @blur="()=>{data.editLabel = false;updateTree()}"
          />
          <span v-show="isEdit">
            
            <el-button
              type="text"
              color="#67C23A"
              size="mini"
              style="color: #67c23a"
              @click="() => editChildrenLabel(node, data)"
            >
              修改
            </el-button>
            <el-button
            v-if="data.level<8"
              type="text"
              size="mini"
              style="color: #409eff"
              @click="() => appendChildren(data)"
            >
              添加{{data.level-3}}级目录
            </el-button>
            <el-button
              type="text"
              size="mini"
              style="color: #f56c6c"
              @click="() => remove(node, data)"
            >
              删除
            </el-button>
          </span>
        </span>
      </el-tree>
      <div class="clearfix" style="margin-top: 16px;margin-left: 16px;">
           <el-button type="primary" @click="submit">全部完成，提交审核</el-button>
        </div>

       
</el-card>
      </el-aside>
      <el-main>
        <el-tabs v-model="activeName" @tab-click="tabClick">
    <el-tab-pane label="题目列表篇" name="0"> <!-- 搜索栏 -->
        <!-- <el-form :inline="true"  class="demo-form-inline">
          <el-form-item label="搜索">
            <el-input
              v-model="searchValue"
              @input="searchInput"
              placeholder="请输入关键词"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-search"
              @click="search"
              >查询</el-button
            >
          </el-form-item>

          <el-form-item>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="addmore"
              >添加</el-button
            >
          </el-form-item>
        </el-form> -->

        <el-empty description="暂无内容" v-if="!listData || listData.length==0"></el-empty>
        <el-card v-for="list,index in listData" :key="index"
        class="box-card">
  <div slot="header" class="clearfix">
    <span>{{ list.title }}</span>
  </div>
   <!-- 表单部分 -->
        <el-table
        
          size="small"
          :data="list.examTopicList"
          highlight-current-row
          stripe
          v-loading="loading"
          border
          element-loading-text="拼命加载中"
          style="width: 100%"
        >
          <el-table-column
            prop="examTopicId"
            label="题目id"
            width="66"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="examTopicContent"
            label="题目"
            width="360"
            align="center"
          >
          <template slot-scope="scope">
            <div v-html="scope.row.examTopicContent" style='white-space: pre-wrap;'></div>
          </template>
        </el-table-column>
          <el-table-column
            prop="examTopicAnswer"
            label="答案"
            width="360"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="copyright"
            label="版权"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="recommend"
            label="推荐"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="makeError"
            label="出错"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="collect"
            label="收藏"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column prop="desc" label="备注" width="240" align="center">
          </el-table-column>

          <el-table-column align="left" label="操作" width="240" fixed="right">
            <template slot-scope="scope">
              <div style="margin: 8px">
                <el-button
                  size="mini"
                  type="success"
                  icon="el-icon-edit"
                  @click="edit(scope.row.examTopicId)"
                >
                  修改
                </el-button>
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="del(scope.row.examTopicId)"
                >
                  删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
</el-card></el-tab-pane>
    <el-tab-pane label="富文本上传" name="1">
      <!-- 富文本嵌套 -->
      <div class="iframe-container">
    <iframe :src="iframeUrl"></iframe>
  </div>
    </el-tab-pane>
  </el-tabs>
      </el-main>
    </el-container>
  </el-container>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  data(){
    return {
      isTable:'a',
      type:'选择题',//题目类型
      options: [
          "填空题",
          "选择题",
          "判断题",
          "问答题",
          "作文",
        ],
      activeName:'0',
      task:{},
      iframeUrl: 'tinymce-main/tinymce/index.html?title=测试',
      isEdit:true,
      asideWidth:'500px',//左侧栏宽度
      //选择年级科目,
      // gradeValue: "1",
      // subjectValue: "1",
      // editionName: "人教版上册", //版本上下册
      // gradeOption: [
      //   // { label: "选择年级", value: "0" },
      //   { label: "一年级", value: "1" },
      //   { label: "二年级", value: "2" },
      //   { label: "三年级", value: "3" },
      //   { label: "四年级", value: "4" },
      //   { label: "五年级", value: "5" },
      //   { label: "六年级", value: "6" },
      //   { label: "七年级", value: "7" },
      //   { label: "八年级", value: "8" },
      //   { label: "九年级", value: "9" },
      //   { label: "高一", value: "10" },
      //   { label: "高二", value: "11" },
      //   { label: "高三", value: "12" },
      // ],
      // subjectOption: [
      //   // { label: "选择科目", value: "0" },
      //   { label: "语文", value: "1" },
      //   { label: "数学", value: "2" },
      //   { label: "英语", value: "3" },
      //   { label: "道法", value: "4" },
      //   { label: "历史", value: "5" },

      //   { label: "地理", value: "6" },
      //   { label: "物理", value: "7" },
      //   { label: "化学", value: "8" },
      //   { label: "生物", value: "9" },
      //   { label: "科学", value: "10" },
      // ],
      // editionOption: [
      //   "人教版上册",
      //   "人教版下册",
      // ],

      //目录
      data : [
        // {
        //   id: 1,
        //   label: "第一单元",
        //   editLabel: false,
        //   level:5,
        //   children: [
        //     {
        //       id: 4,
        //       level:6,
        //       label: "Unit 1 ",
        //       editLabel: false,
        //     },
        //     {
        //       id: 5,
        //       level:6,
        //       label: "Unit 2 ",
        //       editLabel: false,
        //     },
        //     {
        //       id: 6,
        //       level:6,
        //       label: "Unit 3 ",
        //       editLabel: false,
        //     },
        //     {
        //       id: 7,
        //       level:6,
        //       label: "Unit 4",
        //       editLabel: false,
        //     },
        //     {
        //       id: 8,
        //       level:6,
        //       label: "Unit 5 ",
        //       editLabel: false,
        //     },
        //     {
        //       id: 9,
        //       level:6,
        //       label: "Unit 6 ",
        //       editLabel: false,
        //     },
        //     {
        //       id: 10,
        //       level:6,
        //       label: "Unit7 ",
        //       editLabel: false,
        //     },
        //     {
        //       id: 11,
        //       level:6,
        //       label: "Unit 8 ",
        //       editLabel: false,
        //     },
        //     {
        //       id: 12,
        //       level:6,
        //       label: "Unit 9 ",
        //       editLabel: false,
        //     },
        //   ],
        // },
        // {
        //   id: 2,
        //   level:5,
        //   label: "第二单元",
        //   editLabel: false,
        //   children: [
        //     {
        //       id: 13,
        //       level:6,
        //       label: "二级 2-1",
        //       editLabel: false,
        //     },
        //     {
        //       id: 14,
        //       level:6,
        //       label: "二级 2-2",
        //       editLabel: false,
        //     },
        //   ],
        // },
        // {
        //   id: 3,
        //   level:5,
        //   label: "第三单元",
        //   editLabel: false,
        //   children: [
        //     {
        //       id: 15,
        //       level:6,
        //       label: "二级 3-1",
        //       editLabel: false,
        //     },
        //     {
        //       id: 16,
        //       level:6,
        //       label: "二级 3-2",
        //       editLabel: false,
        //     },
        //   ],
        // },
      ],
      listData: [], //题目列表
      maxId:'',//最大id
    }
    
  },
  created() {
    this.username=sessionStorage.getItem("workUser-name") || '';
      // this.editionInit();
      this.init()
    console.log(this.$route.query)
    },
  methods:{
    init() {
      this.task=JSON.parse(this.$route.query.task) || {}
      console.log(this.task)
      
      //获取目录
      this.loading = true;
      let params = {
        exerciseColumnId:this.task.exerciseColumnId,
        username: this.username,
      };
      this.req("POST", "study/appExerciseColumnCopy1/selectChapterAndSection", params).then((res) => {
        this.loading = false;
        if (res && res.code == 200) {
          this.data=res.data
          // this.pageparm.total = res.total;
          // this.listData = res.data;
        }else{
          this.$message.error( "网络错误")
        }
      });
    },
    // editionInit(){
    //   this.req('POST','study/appColumn/selectEdition',{
    //     subjectId: this.subjectValue,
    //     gradeId: this.gradeValue,
    //   }).then(res=>{
    //     console.log(res)
    //     if(res.code == 200 && res.data.length > 0){
    //       this.editionOption=res.data
    //       this.editionName=res.data[0]
    //       // this.init() 获取书籍名
    //     }else{
    //       this.$message.error('暂无版本分类，请在栏目添加');
    //     }
    //   })
    // },

    //添加一级分类
    appendMain() {
        //获取树形图中最大id
      let arr=  this.getAllIds(this.data)
      this.maxId = Math.max.apply(null,arr);
        // this.data.push({
        //   id: maxId+1,
        //   name: "单元",
        //   editLabel: true,
        //   children: [],
        // });
        let unit=JSON.parse(JSON.stringify(this.data[0]))
        unit.editLabel=true
        this.editIds(unit)
        console.log('unit',unit)
        this.data.push(unit)
      }, 
      editIds(node){
        this.maxId=this.maxId+1
        node.id=this.maxId
        node.edit='改过了'
        if(node.children){
          node.children.forEach((item,index)=>{
            this.editIds(item)
          })
        }

      },
      //添加子分类
      appendChildren(data) {
       //获取树形图中最大id
      let arr = this.getAllIds(this.data);
      var maxId = Math.max.apply(null, arr);
      let name = "";
      // if (data.level == 5) {
      //   name = "章节";
      // }
      // if (data.level == 6) {
      //   name = "试卷";
      // }
      // if (data.level == 7) {
      //   name = "题目";
      // }
      const newChild = {
        id: maxId + 1,
        level: Number(data.level) + 1,
        name: name,
        editLabel: true,
        children: [],
      };
      if (!data.children) {
        this.$set(data, "children", []);
      }
      data.children.push(newChild);
      },
      editChildrenLabel(node, data) {
        //修改分类名
        console.log("node", node);
        console.log("data", data);
        // let select=  this.$refs.tree.getCheckedNodes()//获取选中节点
        // console.log('select',select)
        data.editLabel = true;
      },
      remove(node, data) {
        //删除节点
        this.$confirm("永久删除分类,可能会导致其它错误，是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const parent = node.parent;
            const children = parent.data.children || parent.data;
            const index = children.findIndex((d) => d.id === data.id);
            children.splice(index, 1);

            this.updateTree(data.id);
          })
          .catch(() => {});
      },
      //选中节点
      treeCheck(node,list) {
        // this.isEdit=false;
        // this.asideWidth='200px'

        if (list.checkedKeys.length == 2) {
            //单选实现
            this.$refs.tree.setCheckedKeys([node.id]);
     }
     if(node.level==8){
        this.topicTitle=node.name
      }else{
        this.topicTitle=''
      }
     console.log('选中',node,list)
    //  document.querySelector('#myFrameId').src=
    this.appExamId = node.appExamId || ''; 
let that=this

    if(node.appExamId){

      if(this.activeName=='0'){
        this.getNodes();
      }else{
       
       setTimeout(()=>{
        this.iframeUrl=`tinymce-main/tinymce/index.html?appExamId${that.appExamId}&username=${that.username}&type=${that.type}&title=${that.topicTitle}&isTable=${this.isTable}`},300)
       
       
       console.log(this.iframeUrl)
    
      }
      
    }
      
      },
      isEditChange(){
        if(this.isEdit){
          this.isEdit=false;
          this.asideWidth='200px'
        }else{
          this.isEdit=true
          this.asideWidth='500px'
        }
        
      },
      //获取树形图中所有id
      getAllIds(arr) {
    let ids = [];
    arr.forEach(item => {
      ids.push(item.id);
      if (item.children) {
        ids = ids.concat(this.getAllIds(item.children));
      }
    });
    return ids;
  },
  //更新远程树形图
  updateTree(delId) {
      let params = {
        treeList: this.data,
        bookId: this.task.exerciseColumnId,
        delId
      };
      this.req("POST", "study/appExerciseColumnCopy1/updateTree", params).then(
        (res) => {
          if (res.code == 200) {
            this.init()
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
  tabClick(tab, event){
      if(this.activeName=='0'){
        this.getNodes();
      }else{
       this.iframeUrl=`tinymce-main/tinymce/index.html?appExamId=${this.appExamId}&type=${this.type}&title=${this.topicTitle}&isTable=${this.isTable}`
       console.log(this.iframeUrl)
      }

    

    },
  //获取试卷
  getNodes() {
      let params = {
        appExamId: this.appExamId,
        username: this.username,
        // appExamId: 14,
      };
      this.req("POST", "study/appExamTopicCopy1/selectTopic", params).then((res) => {
        if (res.code == 200) {
          this.listData = res.data;
          // this.pageparm.total = res.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    edit(examTopicId) {
      // console.log('index',res)
      // console.log('row',row)
      this.dialogId = examTopicId;

      this.title = "修改题目";
      this.editFormVisible = true;
      // this.$router.push({
      //   path: "/kaodehao/addQuestion",
      //   query: { examTopicId },
      // });
    },
    del(id) {
      this.$confirm("此操作将永久删除数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //http://192.168.2.105:9131/study/appExamTopic/deleteTopic
          this.req("POST", "study/appExamTopicCopy1/deleteTopic", {
            ids: [id],username: this.username,
          }).then((res) => {
            this.getNodes();
          });
        })
        .catch(() => {});
    },
    submit(){
      this.$confirm("请确认您已经完成了所有内容?", "确认提交", {
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //获取taskId
          this.req("POST", "study/task/updateStatus", {
            "status": "2", "taskId": this.task.taskId,username: this.username,
          }).then((res) => {
           this.$message({
          message: '提交审核成功，可以接取新任务了',
          type: 'success'
        })
          });
        })
        .catch(() => {});
    }

  }
}
</script>
<style>
.clearfix{
  text-align: start;
}
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
  .iframe-container {
  width: 720px;
  height: 1000px;
}
.box-card{
  margin-top: 32px;
}
iframe {
  width: 800px;
  height: 940px;
}
</style>