import axios from 'axios'// 引入axios
import router from '../router/index.js'//引入路由
import { Message } from "element-ui";//引入element-Ui的加载组件
// alert(process.env.NODE_ENV)
// console.log(process.env.NODE_ENV)
const isProduction = process.env.NODE_ENV == 'production'
// 'development' 开发环境
// 'production'  线上环境
// 'testing'     测试环境//目前不纯在
// || process.env.NODE_ENV == 'testing'


//测试环境
 //线上环境接口

//let baseURL='http://192.168.0.13:9131/'  //开发环境加测试环境接口
let baseURL='http://39.107.49.143:9131/' //线上测试



// let baseURL=isProduction?

    //  'http://101.200.120.250:8093/':  //线上环境

//       'http://8.141.162.116:8087/';    //开发测试环境

let headers={
    'Content-Type': 'application/json;charset=UTF-8',
}

let whiteName='login'


// 通用公用方法
const req = (method, url, params) => {
    // let token = sessionStorage.getItem('workUser-token') || ''
    // if(token){//如果有token请求头加入token
    // //    console.log('token: ' , token)
    //     headers={
    //            'Content-Type': 'application/json;charset=UTF-8',
    //             'token':token
    //         }
    // }else{//没有token
    //     if(url.indexOf(whiteName)>-1){
    //  //      console.log('白名单',url.indexOf(whiteName))
    //      //   如果在登录页(白名单)
    //        headers={
    //         'Content-Type': 'application/json;charset=UTF-8',
    //      } 
    //     }else{

    //         //不在登录页，重定向到login
    //   //     console.log('不在白名单',url.indexOf(whiteName))
    //         Message.error('token错误！请重新登录！')
    //         router.push({ path: `/login`})
    //     }
        
    // }

   
    return axios({//拿到接口以及请求方式，和参数，统一发起请求
        method: method,
        url: baseURL + url,
        headers:headers,
        data: params,
    }).then(res => {
        if(res.data.code == '200'){//当code等于200时为接口正常返回，此时会return回去数据
          return res.data
        }else{//当接口code不等于200时，就代表借口异常，不会返回数据，并弹出错误
            console.log('请求报错：'+res.data.code)
            typeof Message.error(res.data.message)
            // return res.data
        }
    }).catch(res => {//当接口未知或中断时，弹出参数
        typeof Message.error('出现问题，请稍后刷新页面')
        // return
    })
};
export const bUrl=baseURL//暴露出上传文件，及下载所用的公用域名
export default req
